<template>
  <div class="container pt-56 pt-md-80 mt-lg-16 pb-32 pb-lg-80 mb-md-48">
    <div class="row justify-content-center">
      <div class="col-12 mb-40">
        <h2 class="text-center mb-0">
          {{ $label('page.usps.title') }}
        </h2>
      </div>

      <div class="col-12 col-lg-10">
        <div class="usps-list row">
          <div
            v-for="usp in usps"
            :key="usp.key"
            class="usp__wrapper col-12 col-lg-6 mb-40 mb-lg-40"
          >
            <USP
              :title="$label(usp.title)"
              :text="$label(usp.text)"
              :icon-background-color="iconBackgroundColor"
            >
              <template #icon>
                <component :is="usp.icon" class="usp__icon"></component>
              </template>
            </USP>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import USP from './USP'
import DevicesIcon from '~/assets/icons/devices.svg'
import GiftBoxIcon from '~/assets/icons/gift-box.svg'
import BookHeartIcon from '~/assets/icons/book-heart.svg'
import PiggyBankIcon from '~/assets/icons/piggy-bank.svg'
import USPFlexIcon from '~/assets/icons/usp-flex.svg'

export default {
  components: {
    USP,
    DevicesIcon,
    GiftBoxIcon,
    PiggyBankIcon,
    BookHeartIcon,
    USPFlexIcon,
  },
  props: {
    iconBackgroundColor: {
      type: String,
      default: undefined,
    },
    usps: {
      type: Array,
      default: () => [
        {
          key: '1',
          title: 'page.usps.simpleTitle',
          text: 'page.usps.simpleText',
          icon: 'GiftBoxIcon',
        },
        {
          key: '2',
          title: 'page.usps.ownItTitle',
          text: 'page.usps.ownItText',
          icon: 'BookHeartIcon',
        },
        {
          key: '3',
          title: 'page.usps.everyMonthTitle',
          text: 'page.usps.everyMonthText',
          icon: 'PiggyBankIcon',
        },
        {
          key: '4',
          title: 'page.usps.readDevicesTitle',
          text: 'page.usps.readDevicesText',
          icon: 'DevicesIcon',
        },
      ],
    },
  },
}
</script>

<template>
  <div class="usp">
    <div class="row g-0 d-flex">
      <div
        class="col-3 d-flex align-items-start justify-content-center align-items-lg-center"
      >
        <div
          class="usp__icon-background d-flex justify-content-center align-items-center"
          :style="{ backgroundColor: iconBackgroundColor }"
        >
          <slot name="icon" />
        </div>
      </div>

      <div class="col-8 d-flex justify-content-center flex-column">
        <h4 class="mb-16">
          {{ title }}
        </h4>
        <p class="mb-0 text-small">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconBackgroundColor: {
      type: String,
      default: '#EEF4F2',
    },
  },
}
</script>

<style lang="scss">
.usp {
  justify-content: flex-start;
  text-align: left;

  .row {
    justify-content: space-evenly;
  }
}

.usp__icon-background {
  padding: 24.8%;
  mask: url('~/assets/icons/ellipse42.svg') no-repeat 50% 50%;
  mask-size: 100%;
}

.usp__wrapper:first-of-type {
  .usp__icon-background {
    mask: url('~/assets/icons/ellipse40.svg') no-repeat 50% 50%;
    mask-size: 100%;
  }
}
.usp__wrapper:nth-of-type(2) {
  .usp__icon-background {
    mask: url('~/assets/icons/ellipse41.svg') no-repeat 50% 50%;
    mask-size: 100%;
  }
}

.usp__icon {
  width: 24px;
  height: 24px;
}

@include media-breakpoint-up(md) {
  .usp__icon {
    width: 40px;
    height: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .usp__icon-background {
    padding: 34.2%;
  }

  .usp__icon {
    width: 48px;
    height: 48px;
  }

  .usp .row {
    justify-content: space-between;
  }
}
</style>
